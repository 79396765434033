/*
 * @Author: 文博
 * @Description:
 * @Date: 2023-03-29 15:16:56
 * @LastEditTime: 2024-10-01 17:38:27
 * @FilePath: \business\src\api\customerFare.ts
 */
// 金大盘价
import { get, post } from './http'
// 分页查询所有
const path = '/business'
const API: any = {
  personManager: '/user/user/selectAll', // 分配管理新增姓名
  getDept: '/user/department/tree', // 分配管理仓库
  assignPersonnelList: path + '/assignPersonnel/list', // 分配管理用户列表
  assignPersonnelInsert: path + '/assignPersonnel/insert', // 分配管理用户添加
  assignPersonnelEdit: path + '/assignPersonnel/edit', // 分配管理用户修改
  assignPersonnelDelete: path + '/assignPersonnel/delete', // 分配管理用户删除
  assignPersonnelModifyState: path + '/assignPersonnel/modifyState', // 分配管理用户状态修改
  getAddClues: path + '/clue/insert', // 线索管理-添加线索
  getQueryClues: path + '/clue/list', // 线索管理-查询列表
  getCluesDetailById: path + '/clue/detail', // 线索管理-详情
  getClueEditById: path + '/clue/edit', // 线索管理-编辑
  getClueDeleteById: path + '/clue/delete', // 线索管理-删除
  getCluehandOver: path + '/clue/handOver', // 线索管理-移交
  getClueAssignment: path + '/clue/assignment', // 线索管理-分配
  getClueModifyState: path + '/clue/modifyState', // 线索管理-沟通
  getDeleteBatch: path + '/clue/deleteBatch', // 线索管理-批量删除
  getAssignmentBatch: path + '/clue/assignmentBatch', // 线索管理-批量分配
  reviseCurrentAssignee: path + '/assignPersonnel/reviseCurrentAssignee', // 线索管理-修改当前分配人
  currentAssignee: path + '/assignPersonnel/currentAssignee', // 线索管理-当前分配人
  getAssignPersonnel: path + '/assignPersonnel/getOne', // 查询当前用户线索分配人员数据
  editMyselfState: path + '/assignPersonnel/editMyselfState', // 当前用户修改线索分配人员状态 自己
  allOaCitys: '/report/networkDepartment/allOaCitys', // 所有城市
  selectAllsCategory: '/report/biddingCategory/selectAlls', // 所有类目
  selectAllChannels: '/report/biddingChannel/selectAll', // 渠道管理-所有渠道
  queryAllChannel: '/report/networkChannel/queryByPage', // 渠道管理-所有渠道
  deptChannel: path + '/deptChannel/selectByPage', // 渠道管理-根据条件查询配置列表
  deptChannelInsert: path + '/deptChannel/insert', // 渠道管理-添加部门渠道关系配置
  deptChannelEdit: path + '/deptChannel/edit', // 渠道管理-修改部门渠道关系配置
  deptChannelDel: path + '/deptChannel/delete', // 渠道管理-删除部门渠道关系配置
  assignGroupsList: path + '/assignGroups/list', // 渠道管理-分配部门下拉框
  currentDeptChannel: path + '/deptChannel/currentDeptChannel', // 线索管理新增 - 当前用户部门渠道
  oaAreaCity: '/report/oaArea/selectAll', // 所有城市
  checkPhoneRepeat: path + '/clue/checkPhoneRepeat', // 联系方式查重
  newCheckPhoneRepeat: path + '/clue/checkPhoneRepeatDetail', // 新联系方式查看
  labelConfigInsert: path + '/labelConfig/insert', // 添加标签配置
  labelConfigDelete: path + '/labelConfig/delete', // 删除标签配置
  labelConfigEdit: path + '/labelConfig/edit', // 修改标签配置
  selectByPage: path + '/labelConfig/selectByPage', // 根据条件查询配置列表
  exportExcel: path + '/clue/export', // 线索管理导出
  getBiddingClueList: path + '/otherClue/findByPage', // 竞价线索列表
  visitRecordAdd: path + '/visitRecord/add', // 新增来访记录
  findByPageVisit: path + '/visitRecord/findByPage', // 来访记录列表
  visitRecordRemove: path + '/visitRecord/remove', // 删除来访记录
  visitRecordEdit: path + '/visitRecord/edit', // 编辑到访记录
  // appraiserList: '/report/yyzxAppraiser/appraiserList', // 鉴定师列表
  exportDataVisit: path + '/visitRecord/exportData', // 导出来访记录
  getVisitRecordAllExport: path + '/visitRecord/exportHideData', /// / 导出来访记录
  ocFindByList: '/reporteasy/ocEdifact/findByList', // 到店信息
  appraiserList: '/report/yyzxAppraiser/getAppraiser', // 鉴定师列表
  getClueList: path + '/clue/getClueList', // 线索统计
  getLinkOwnerList: path + '/linkOwner/findByPage', // 链接负责人列表
  getLinkOwnerAdd: path + '/linkOwner/add', // 添加链接负责人
  getLinkOwnerDelete: path + '/linkOwner/removeBatch', // 删除 链接负责人
  getLinkOwnerEdit: path + '/linkOwner/edit', // 编辑链接负责人
  getVisitUserList: path + '/visitUserConfig/getVisitUserConfig', // 鉴定师列表
  getVisitUserAdd: path + '/visitUserConfig/saveVisitUserConfig', // 添加鉴定师,编辑
  getVisitUserDelete: path + '/visitUserConfig/delVisitUserConfig', // 删除 鉴定师
  // getVisitUserEdit: path + '/linkOwner/edit', // 编辑鉴定师
  getVisitUserAppraiseList: path + '/visitUserConfig/getGroupList', // 鉴定师列表
  getCreateUserDeptList: path + '/visitUserConfig/getGropS', // 创建人部门列表
  getAccountNameList: path + '/accountRelationship/findByPage'// 根据提交人id获取账户名称

}

export const personManagerApi = (data: any) => get(API.personManager, data)// 分配管理新增姓名
export const getDeptApi = () => get(API.getDept)// 分配管理仓库
export const assignPersonnelListApi = (data: any) => post(API.assignPersonnelList, data, '')// 分配管理用户列表
export const assignPersonnelInsertApi = (data: any) => post(API.assignPersonnelInsert, data, '')// 分配管理用户添加
export const assignPersonnelEditApi = (data: any) => post(API.assignPersonnelEdit, data, '')// 分配管理用户修改
export const assignPersonnelDeleteApi = (data: any) => get(API.assignPersonnelDelete, data)// 分配管理用户删除
export const assignPersonnelModifyStateApi = (data: any) => get(API.assignPersonnelModifyState, data)// 分配管理用户状态修改
export const getAddCluesApi = (data: any) => post(API.getAddClues, data, '')// 线索管理-添加线索
export const getQueryCluesApi = (data: any) => post(API.getQueryClues, data, '')// 线索管理-查询列表
export const getCluesDetailByIdApi = (data: any) => post(API.getCluesDetailById, data, '')// 线索管理-详情
export const getClueEditByIdApi = (data: any) => post(API.getClueEditById, data, '')// 线索管理-编辑
export const getClueDeleteByIdApi = (data: any) => post(API.getClueDeleteById, data, '')// 线索管理-删除
export const getCluehandOverApi = (data: any) => post(API.getCluehandOver, data, '')// 线索管理-移交
export const getClueAssignmentApi = (data: any) => post(API.getClueAssignment, data, '')// 线索管理-分配
export const getClueModifyStateApi = (data: any) => post(API.getClueModifyState, data, '')// 线索管理-沟通
export const getDeleteBatchApi = (data: any) => post(API.getDeleteBatch, data, '')// 线索管理-批量删除
export const getAssignmentBatchApi = (data: any) => post(API.getAssignmentBatch, data, '')// 线索管理-批量分配
export const reviseCurrentAssigneeApi = (data: any) => post(API.reviseCurrentAssignee, data, '')// 线索管理-修改当前分配人
export const currentAssigneeApi = (data: any) => get(API.currentAssignee, data)// 线索管理-当前分配人
export const getAssignPersonnelApi = () => get(API.getAssignPersonnel)// 查询当前用户线索分配人员数据
export const editMyselfStateApi = (data: any) => post(API.editMyselfState, data, '')// 当前用户修改线索分配人员状态 自己
export const allOaCitysApi = () => get(API.allOaCitys)// 所有城市
export const selectAllsCategoryApi = () => get(API.selectAllsCategory)// 所有类目
export const selectAllChannelsApi = () => get(API.selectAllChannels)// 所有渠道
export const queryAllChannelApi = (data: any) => get(API.queryAllChannel, data)// 所有渠道
export const deptChannelApi = (data: any) => post(API.deptChannel, data, '')// 根据条件查询配置列表
export const deptChannelInsertApi = (data: any) => post(API.deptChannelInsert, data, '')// 根据条件查询配置列表
export const deptChannelEditApi = (data: any) => post(API.deptChannelEdit, data, '')// 修改部门渠道关系配置
export const deptChannelDelApi = (data: any) => post(API.deptChannelDel, data, '')// 删除部门渠道关系配置
export const assignGroupsListApi = () => get(API.assignGroupsList)// 分配部门下拉框
export const currentDeptChannelApi = () => get(API.currentDeptChannel)// 线索管理新增 - 当前用户部门渠道
export const oaAreaCityApi = (data: any) => get(API.oaAreaCity, data)// 线索管理新增 - 当前用户部门渠道
export const checkPhoneRepeatApi = (data: any) => post(API.checkPhoneRepeat, data, '')// 联系方式查重
export const newCheckPhoneRepeatApi = (data: any) => post(API.newCheckPhoneRepeat, data, '')// 新的联系方式查重
export const labelConfigInsertApi = (data: any) => post(API.labelConfigInsert, data, '')// 添加标签配置
export const labelConfigDeleteApi = (data: any) => post(API.labelConfigDelete, data, '')// 删除标签配置
export const labelConfigEditApi = (data: any) => post(API.labelConfigEdit, data, '')// 修改标签配置
export const selectByPageApi = (data: any) => post(API.selectByPage, data, '')// 根据条件查询配置列表
export const exportExcelApi = (data: any) => post(API.exportExcel, data, { responseType: 'blob' }) // 线索管理导出
export const getBiddingClueListApi = (data: any) => post(API.getBiddingClueList, data, '')// 竞价线索列表
export const visitRecordAddApi = (data: any) => post(API.visitRecordAdd, data, '')// 新增来访记录
export const findByPageVisitApi = (data: any) => post(API.findByPageVisit, data, '')// 来访记录列表
export const visitRecordRemoveApi = (data: any) => post(API.visitRecordRemove, data, '')// 删除来访记录
export const visitRecordEditApi = (data: any) => post(API.visitRecordEdit, data, '')// 编辑到访记录
// export const appraiserListApi = (data: any) => get(API.appraiserList, data)// 鉴定师列表
export const exportDataVisitApi = (data: any) => post(API.exportDataVisit, data, { responseType: 'blob' })// 导出来访记录
export const getVisitRecordAllExportApi = (data: any) => post(API.getVisitRecordAllExport, data, { responseType: 'blob' })// 导出来访记录
export const ocFindByListApi = (data: any) => get(API.ocFindByList, data)// 到店信息
export const appraiserListApi = () => get(API.appraiserList)// 鉴定师列表
export const getClueListApi = (data: any) => post(API.getClueList, data, '')// 线索统计
export const getLinkOwnerListApi = (data: any) => post(API.getLinkOwnerList, data, '')// 链接负责人列表
export const getLinkOwnerAddApi = (data: any) => post(API.getLinkOwnerAdd, data, '')// 添加链接负责人
export const getLinkOwnerDeleteApi = (data: any) => post(API.getLinkOwnerDelete, data, '')// 删除 链接负责人
export const getLinkOwnerEditApi = (data: any) => post(API.getLinkOwnerEdit, data, '')// 编辑链接负责人
export const getVisitUserListApi = (data: any) => post(API.getVisitUserList, data, '')// 链接鉴定师列表
export const getVisitUserAddApi = (data: any) => post(API.getVisitUserAdd, data, '')// 添加链接鉴定师
export const getVisitUserDeleteApi = (data: any) => post(API.getVisitUserDelete, data, '')// 删除 链接鉴定师
export const getVisitUserAppraiseListApi = (data: any) => post(API.getVisitUserAppraiseList, data, '')// 删除 链接鉴定师
export const getCreateUserDeptListApi = (data: any) => post(API.getCreateUserDeptList, data, '')// 删除 链接鉴定师
export const getAccountNameListApi = (data: any) => post(API.getAccountNameList, data, '')
